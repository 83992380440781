.article-section {
    padding: 50px;
   
    background-color: #f5f5f5;

  }
  
  .article-title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    
  }
  
  .article-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensures the items wrap on smaller screens */
    gap: 20px;
  }
  
  .article-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    width: 100%;
    text-align: center;
    transition: transform 0.3s ease;
    flex: 1 1 calc(33.33% - 40px); /* Each card takes up 1/3rd of the row minus gap */
    box-sizing: border-box; /* Ensures padding doesn’t affect width */
  }
  
  .article-card:hover {
    transform: translateY(-10px);
  }
  
  .article-title-text {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .article-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .article-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .article-link:hover {
    text-decoration: underline;
  }
  
  /* Make sure the layout adjusts on smaller screens */
  @media (max-width: 768px) {
    .article-card {
      flex: 1 1 calc(50% - 40px); /* For tablet/smaller screens, cards take 50% */
    }
  }
  
  @media (max-width: 480px) {
    .article-card {
      flex: 1 1 100%; /* On small screens, cards take full width */
    }
  }
  