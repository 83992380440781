.contact-section {
    /* Space inside the section */
    
    margin: 0 auto; /* Center the section */
     /* Optional: sets a max width */
    background-color: #f5f5f5; /* Section background */
    padding-left: 1vw;
    padding-bottom: 2vh;
  }
  
  .borderBox{
    
    border: 2px solid black;  /* Thin black border */
    box-sizing: border-box;   /* Ensures padding is included in width/height */
    height: 96vh;             /* Full viewport height */
    width: 98vw;            /* Enables Flexbox */
    justify-content: center;  /* Centers content horizontally */
    align-items: center;      /* Centers content vertically */
    
  }

  
  .contact-title {
    margin-top: 17vh;
    margin-bottom: 0px;
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: clamp(18px, 2.5vw, 36px); /* Lower responsive range: Minimum: 18px, Maximum: 48px */
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    
  }
  
  .contact-form {
    width: 100%; /* Ensure the form fits inside the container */
    max-width: 500px; /* Set a max width for the form itself */
    margin: 0 auto;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 2vh;
    text-align: left;
    padding-left: 20vw;
    padding-right: 20vw;
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #000000;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 550;
     /* Center the text */
}
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.3s;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 300;
    /* Center the text */
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #007bff;
    
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 120px;
    
  }
  
 
  
  .contact-submit {
    background-color: rgb(0, 0, 0);
    color: white;
    border: 1px solid black;   /* Thin black border */
    box-sizing: border-box;    /* Ensures padding is included in width/height */
    border-radius: 5%;          /* Square corners */
    padding: 6px;
    margin-left: 10px;
    font-size: 1vw;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    
    
    
    font-size: clamp(14px, 2.5vw, 18px); /* Lower responsive range: Minimum: 18px, Maximum: 48px */

  }
  
  .contact-submit:active {
   
    background-color:  rgb(55, 172, 235);
    border: 1px solid rgb(55, 172, 235);
    color: white;
  }
  

  .contact-submit:hover {
    background-color:  rgb(55, 172, 235);
    border: 1px solid rgb(55, 172, 235);
    color: white;
  }
  
  