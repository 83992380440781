.about-image {
    object-fit: cover;
    width: 40vw; /* Adjust this if you need */
    height: auto;
    max-width: 400px;
    max-height: 80vh;
    border-radius: 5%;
    margin-bottom: 20px; /* Space between image and text */
  }
  
  .typing-container {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  
  .typing-text {
    font-size: 5vw; /* Font size is now 10% of the viewport width */
    color: var(--color-primary);
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 300;
    text-align: center;  /* Center the text */
  }
  
  .cursor {
    font-size: 5vw; /* Font size matches the text */
    color: var(--color-primary);
    animation: blink 1s step-end infinite;
  }

  .about-section {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Dark blue background */
}

.icon-container {
    position: absolute; /* This will make the icons position absolutely within the container */
    top: 4vh; /* Distance from the top */
    right: 2vw; /* Distance from the right */
    display: flex; /* Ensures icons are laid out in a row */
     /* Adds space between the icons */
  }

.icon {
  color: black;
  font-size: 30px; /* Adjust icon size */
  margin-left: 15px;
  transition: color 0.3s ease; /* Add hover effect */
}

.icon:hover {
  color: red; /* Lighter blue on hover */
}

.typing-container {
  font-size: 3rem;
  color: white;
}

.cursor {
  font-size: 3rem;
  color: white;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
  
  /* Blinking cursor animation */
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  
  @media (max-width: 100px) {
    .typing-text, .cursor {
      font-size: 8vw; /* Ensure readability on smaller screens */
    }
  }
  
  .about-section {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #000000); /* Static gradient colors */
    background-size: cover; /* Make the gradient cover the entire section */
  }
  
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  .container {
    border: 2px solid black;  /* Thin black border */
    box-sizing: border-box;   /* Ensures padding is included in width/height */
    height: 96vh;             /* Full viewport height */
    width: 98vw; 
    display: flex;            /* Enables Flexbox */
    justify-content: center;  /* Centers content horizontally */
    align-items: center;      /* Centers content vertically */
  }

  .menuSpacing{
    position: absolute;
    top: 2vh;
    left: 1.5vw;
    
  }