.projects-section {
    padding: 2px;
    background-color: #f5f5f5;
    padding-bottom: 100px;
  }
  
  .projects-title {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    font-size: clamp(18px, 2.5vw, 36px); /* Lower responsive range: Minimum: 18px, Maximum: 48px */

    
  }
  
  .projects-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .project-card {
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column; /* Ensure content is stacked vertically */
    justify-content: space-between;
    margin: 1vh;
    max-width: 1200px;
    width: 90%;
    align-items: center;
  }
  
  .project-content {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    margin: 2vw;
    margin-top: 0px;
    text-align: left;
    width: 90%;
  }
  
  .project-text {
    flex: 1;
    font-family: proxima-nova, sans-serif;
    font-weight: 300;
    font-size: 1.25rem;
  }
  
  .project-image {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
  }
  
  .project-image img {
    width: 20vw;
    height: auto;
    max-width: 300px;
    border-radius: 8px;
    
  }
  
  .project-title {
     /* Further reduce space below the title */
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
    font-size: clamp(10px, 2vw, 20px); /* Lower responsive range: Minimum: 18px, Maximum: 48px */
    margin-bottom: 1.5vh;
    display: flex;
  }
  
  .project-description {
    font-size: clamp(8px, 1.5vw, 14px);
    margin-top: 0px; /* Further reduce the top margin of the description */
    margin-right: clamp(10px, 2vw, 12px);
    font-weight: 200;
  }
  
  .project-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: clamp(10px, 2vw, 15px);
  }
  
  .project-link:hover {
    text-decoration: underline;
  }
  
  .project-card:hover {
    transform: translateY(-20px);
  }
  
  /* Icon location container styling */
  .icon-location {
    display: flex;
    justify-content: flex-end ; /* Align icons to the left */
    gap: 10px; /* Space between icons */
    margin-top: 20px; /* Add some margin to separate from text */
    margin-right: 10px;
  }
  
  
 /* Flex container for the title, icons, and description */
.project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap; /* Allows items to wrap to the next line if necessary */
  }
  
  /* Title styling */

  /* Icons container */
  .project-skills {
    display: flex;
    
    gap: 0px;
    flex-shrink: 0; /* Prevent icons from shrinking */
  }
  
  /* Description styling */

  
  /* Individual icon styling */
  .project-skill-icon {
    width: 3vw;
    max-width: 40px;
    height: auto;
    object-fit: contain;
   
  }
 

  .doc-submit {
    background-color: white;
    color: black;
    border: 1px solid black;   /* Thin black border */
    box-sizing: border-box;    /* Ensures padding is included in width/height */
    border-radius: 5%;          /* Square corners */
    padding: 6px;
    
    font-size: clamp(8px, 0.95vw, 18px);

    
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 200;
    margin-right: 10px;
    
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    
    
    max-width: 50px;
    margin-top: 2vh;
    
}
  
  .doc-submit:active {
    background-color:  rgb(35, 122, 236);
    border: 1px solid  rgb(35, 122, 236);
    color: white;
  }

  .doc-submit:hover {
    background-color: rgb(35, 122, 236);
    border: 1px solid  rgb(35, 122, 236);
    color: white;
  }

  
  .git-submit {
    background-color: white;
    color: black;
    border: 1px solid black;   /* Thin black border */
    box-sizing: border-box;    /* Ensures padding is included in width/height */
    border-radius: 5%;          /* Square corners */
    padding: 6px;
    margin-left: 0px;
    font-size: clamp(8px, 0.95vw, 18px);

    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 200;
    
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    
  
    max-width: 50px;
    margin-top: 2vh;
  }
  
  .git-submit:active {
   
    background-color:  rgb(0, 0, 0);
    border: 1px solid  rgb(0, 0, 0);
    color: white;
  }
  

  .git-submit:hover {
    background-color: rgb(0, 0, 0);
    border: 1px solid  rgb(0, 0, 0);
    color: white;
  }
  
  
  .kag-submit {
    background-color: white;
    color: black;
    border: 1px solid black;   /* Thin black border */
    box-sizing: border-box;    /* Ensures padding is included in width/height */
    border-radius: 5%;          /* Square corners */
    padding: 6px;
    margin-left: 0px;
    font-size: clamp(8px, 0.95vw, 18px);


    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 200;
    
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    
    max-width: 50px;
    margin-top: 2vh;
  }
  
  .kag-submit:active {
   
    background-color:  red;
    border: 1px solid  red;
    color: white;
  }
  

  .kag-submit:hover {
    background-color: red;
    border: 1px solid  red;
    color: white;
  }
  
  .video {
    width: 15vw; /* Makes the iframe take full width of the container */
    height: 20vw; /* Set a desired height */
    max-width: 600px; /* Optional: Maximum width for the iframe */
    border: none; /* Removes default iframe border */
    border-radius: 10px; /* Adds rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    margin: 20px 0; /* Adds some margin for spacing */
  }

  