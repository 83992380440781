/* Initial state without animation */
.info-image img {
  opacity: 1; 
  transform: translate(0, 0); /* Initial position */
  transition: transform 0.6s ease-in-out; /* Smooth transition for movement */
  border: 2px solid black;
}

/* First backdrop (green box) */
.info-image::before {
  content: '';
  position: absolute;
  width: 99%;
  height: 99%;
  background-color:  rgb(239, 85, 85); /* Orange color for the second box */
  border: 2px solid black;
  border-radius: 5%; /* Rounded corners */
  z-index: 1; /* Behind the image but above the second box */
  top: 0px;  /* Slight offset to make it appear below the image */
  left: 0px;
  transition: transform 0.6s ease-in-out; /* Smooth transition */
  transform: translate(0, 0); /* Initial position */
}

/* Second backdrop (the additional box) */
.info-image::after {
  content: '';
  position: absolute;
  width: 99%; /* Slightly larger than the green box */
  height: 99%; /* Slightly larger than the green box */
  background-color:rgb(55, 172, 235); /* Orange color for the second box */
  border: 2px solid black;
  border-radius: 5%; /* Rounded corners */
  z-index: 0; /* Behind both the green box and the image */
  top: 0px;  /* Offset further behind the green box */
  left: 0px;
  transition: transform 0.6s ease-in-out; /* Smooth transition */
  transform: translate(0, 0); /* Initial position */
}

/* Add class for when the user scrolls halfway down */
.info-image.show img {
  transform: translate(40px, 40px); /* Move the image 30px on both X and Y axis */

}

.info-image.show::before {
  transform: translate(20px, 20px); /* Move the green box in opposite direction */
}

.info-image.show::after {
  transform: translate(0px, 0px); /* Move the orange box slightly */
}

/* Container for the entire "About Me" section */
.info-section {
  position: relative;
  height: auto; /* Adjusts the height to fit content */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5; /* Eggshell/white background */
  padding: 4vh;
  border-bottom-width: 0px;
  border-radius: 0px; /* No rounded corners */
  max-width: 200vw; /* Limit the width to keep the section centered */
  margin: 0px auto; /* Add space around the section */
}

/* Flex container for the image and text */
.info-container {
  background-color: #f5f5f5;
  display: flex;
 
  width: 70vw;
  max-width: 1200px;
 
  align-items: center;
  flex-direction: row;
  margin-top: 10vh;
  margin-bottom: 20vh;
  position: relative;
}

/* Styling for the image */
.info-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10vw;
}

.info-image img {
  width: 40vw;
  height: auto;
  max-width: 400px;
  border-radius: 5%;
  object-fit: cover;
  z-index: 2; /* In front of the green circle */
  opacity: 1;
  transform: translateY(0); /* No transformation */
  transition: transform 0.6s ease-in-out; /* Smooth transition */
}

/* Text styling */
.info-text h1 {
  margin-bottom: 20px;

  font-size: clamp(18px, 2.5vw, 36px); /* Lower responsive range: Minimum: 18px, Maximum: 48px */
  color: black;
  text-align: left;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
}

/* Paragraph styling */
.info-text p {
  font-size: clamp(10px, 1.5vw, 18px); /* Lower responsive range: Minimum: 14px, Maximum: 24px */
  line-height: 1.6;
  color: black;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 300;
  text-align: left;
}

/* Social icons styling */
.social-icons {
  margin-top: 20px;
}

/* Responsive adjustments for small screens */
@media (max-width: 480px) {
  .info-text h1 {
    font-size: 4.76vw; /* Adjusted for mobile screens */
  }
  .info-text p {
    font-size: 2.83vw; /* Adjusted for mobile screens */
  }
  .info-container {
    flex-direction: column; /* Stack the image and text vertically */
    text-align: center; /* Center-align text for smaller screens */
  }
  .info-image {
    margin-right: 0;
    margin-bottom: 20px; /* Add space between the image and text */
  }
}
