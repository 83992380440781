/* Container for the "Learn with Me" section */
.learn-with-me-section {
  padding: 20px;
  background-color: #f5f5f5;
  max-width: 100%; /* Ensure the container doesn’t overflow */
  overflow: hidden; /* Hide any overflow */
}

/* Default styles for larger screens */
.header, .header-2, .header-3 {
  margin-top: 50px;
  display: inline-block;
  font-size: clamp(18px, 2.5vw, 36px); /* Adjust size as necessary */
  font-weight: normal; /* Ensure the font weight matches the style */
  color: rgb(239, 85, 85);
  border: 2px solid black;
  border-radius: 15px;
  padding: 10px 20px; /* Adjust padding for spacing */
  text-align: center;
  font-family: Arial, sans-serif; /* Adjust font if needed */
  background-color: rgb(239, 85, 85); /* Optional, for a light background */
  transition: transform 0.6s ease-in-out; /* Smooth hover effect */
  z-index: 2;
  transform: translate(92%, 20%);
}

.header-2 {
  color: rgb(55, 172, 235);
  background-color: rgb(55, 172, 235);
  transform: translate(-8%, 20%);
}

.header-3 {
  color: black;
  background-color: white;
  transform: translate(-108%, 20%);
}

.header.show {
  opacity: 1;
  transform: translate(84%, 0%); /* Moves to final position */
}

.header-2.show {
  opacity: 1;
}

.header-3.show {
  opacity: 1;
  transform: translate(-100%, 40%); /* Moves slightly less than .header-2 */
}

/* Scroll container for items */
.scroll-container {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Align vertically */
  gap: 0px;
  overflow-x: auto;
  padding: 10px 10px 10px 20px; /* Add padding to the left */
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%; /* Ensure the container takes up the full width */
  scroll-behavior: smooth;
  box-sizing: border-box; /* Make sure padding is included in the width */
}

/* Individual scroll items */
.scroll-item {
  min-width: 200px;
  max-width: 300px;
  width: 20vw;
  padding: 0px;
  flex-shrink: 0; /* Prevent shrinking */
  background-color: #f5f5f5; /* Remove background color */
}

/* Image inside each scroll item */
.scroll-item-image {
  width: 90%;
  border: 1vw solid #f5f5f5;
  border-top: 3vh solid #f5f5f5;
  border-bottom: 3vh solid #f5f5f5;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease; /* Hover effect */
}

/* Hover effect on scroll item images */
.scroll-item-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Hide the scrollbar */
.scroll-container::-webkit-scrollbar {
  display: none;
}

/* Responsive adjustments for iPhones and small screens */
@media (max-width: 768px) {
  .learn-with-me-section {
      padding: 10px; /* Reduce padding for smaller screens */
  }

  .header, .header-3 {
      font-size: clamp(14px, 4vw, 24px); /* Smaller font size for iPhones */
      padding: 8px 16px; /* Adjust padding for smaller screens */
      transform: translate(0, 0); /* Reset transformations */
      margin-top: 20px; /* Reduce margin */
  }

  .header-2{
    font-size: clamp(14px, 4vw, 24px); /* Smaller font size for iPhones */
    padding: 8px 16px; /* Adjust padding for smaller screens */
    transform: translate(-8%, 20%); /* Reset transformations */
    margin-top: 20px; /* Reduce margin */
  }

  .scroll-item {
      min-width: 120px; /* Reduce size for scroll items */
      max-width: 150px;
      width: 30vw; /* Make scroll items responsive */
  }

  .scroll-item-image {
      width: 100%; /* Adjust image size for iPhone */
      border: 1px solid transparent; /* Remove excessive border on small screens */
  }

  .scroll-container {
      padding-top: 30px; /* Reduce padding on smaller screens */
      padding-bottom: 30px;
      gap: 10px; /* Smaller gap between items */
  }

  /* Ensure the hover effect remains smooth */
  .scroll-item-image:hover {
      transform: scale(1.03); /* Slightly smaller hover zoom */
  }
}
