/* Console Style */
:root {
    --color-primary: white;  /* Blue */
    --color-secondary: #6DAFFE; /* Lighter Blue */
    --color-tertiary: #EDF6FF;  /* Very Light Blue */
    --color-background: #192841;  /* White */
    --color-accent: #B9732F;  /* Brownish */
    --color-text: #EDF6FF;
  }


.console {
    background-color: var(--color-primary);
    color: white;
    padding: 15px;
    font-family: "Courier New", Courier, monospace;
    border-radius: 5px;
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  
  .line {
    opacity: 0;
    animation: consolePrint 0.5s ease-in forwards;
  }
  
  .line:nth-child(1) {
    animation-delay: 1s;
  }
  .line:nth-child(2) {
    animation-delay: 2s;
  }
  .line:nth-child(3) {
    animation-delay: 3s;
  }

  /* CSS for Typing Animation */
.typing-container {
    font-size: 32px;
    margin-bottom: 20px;
    color: var(--color-text);
    background-color: var(white);
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    
  }
  
  .typing-text {
    display: inline-block;
    color: black;
    
  }
  
  .cursor {
    display: inline-block;
    font-size: 5vw;
    margin-left: 5px;
    color: black;
    animation: blink 0.7s infinite;

  }

  @media (min-width: 1200px) {
    .typing-text, .cursor {
      font-size: 60px; /* Cap the font size at 60px on large screens */
    }
  }
  
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
 
  