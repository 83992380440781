/* Style for the nav menu container */
.nav-menu {
    position: relative;
    z-index: 100; /* Ensure the menu stays on top of other content */
  }
  
  /* Hamburger button style (move to the left) */
  .menu-button {
    font-size: 45px; /* Size of the menu button (hamburger icon) */
    background: none;
    border: none;
    color: black; /* Set your preferred color */
    cursor: pointer;
    position: fixed;
    top: clamp(10px, 1vh, 15px); /* Position relative to the top of the viewport */
    left:  clamp(10px, 1.5vw, 28px); /* Position relative to the left of the viewport */
    padding: 0;
    margin: 0;
    z-index: 1001; /* Keep the button always visible */
}
  .menu-button:hover {
    color: red; /* Change to red when hovered */
    transition: color 0.3s ease;
  }

  /* Hide the links initially (move to the left) */
  .menu-links {
    display: none; /* Initially hidden */
    position: fixed;
    top: 0;
    left: 1.5vw; /* Move the menu to the left side */
    
    width: 20vw;
    height: 35vh;
    
    background-color: #fff; /* Background color of the menu */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); /* Shadow on the right side now */
    list-style: none;
    padding: 10vh 20px;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); /* Hide off-screen on the left */
    
  }
  
  /* Show the links when menu is open */
  .menu-links.open {
    display: block;

    transform: translateX(0); /* Slide in from the left */
   
  }
  
  /* Style for the links */
  .menu-links li {
    margin: 20px 0;
  }
  
  .menu-links li a {
    text-decoration: none;
    color: black;
    font-size: 20px;
    
  }
  
  /* Optional: Change color on hover */
  .menu-links li a:hover {
    color: red; /* Change to your desired hover color */
  }
  